<template>
  <div>
    <div class="flex-display">
      <el-input
        placeholder="请输入内容"
        v-model="input"
        class="input-with-select"
        style="width: 250px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="sousuo()"
        ></el-button>
      </el-input>
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="clickadd()"
      >
        添加
      </el-button>
      <el-button type="success" @click="muban">导出模板</el-button>
      <el-button type="success" @click="download">批量导出二维码</el-button>
      <el-button type="success" @click="downloads">导出表格</el-button>

      <el-upload
        class="upload-demo"
        action="https://www.hyzfjd.cn/api/ums/umsCompany/importCompany"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :on-success="correct"
        multiple
        :limit="999"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :headers="headers"
        :show-file-list="false"
      >
        <el-button style="margin-left: 10px" type="primary">点击上传</el-button>
      </el-upload>
    </div>
    <el-table
      :data="tableDatas"
      style="width: 100%"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="companyName" label="企业名称"> </el-table-column>
      <el-table-column prop="companyCode" label="企业地址"> </el-table-column>
      <el-table-column prop="country" label="所属区域"> </el-table-column>
      <el-table-column prop="companyLabel" label="企业标签"> </el-table-column>
      <el-table-column prop="companyContacts" label="企业联系人">
      </el-table-column>
      <el-table-column prop="companyContactsPhone" label="联系人电话">
      </el-table-column>

      <el-table-column label="操作" width="250px">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleUpdate(scope.row)"
          >
            编辑
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">
            删除
          </el-button>
          <el-button type="success" size="mini" @click="downloadImg(scope.row)"
            >导出二维码
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageNum"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%">
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="企业名称">
          <el-input v-model="formLabelAlign.companyName"></el-input>
        </el-form-item>
        <el-form-item label="企业地址">
          <el-input v-model="formLabelAlign.companyCode"></el-input>
        </el-form-item>
        <el-form-item label="所属区域">
          <el-input v-model="formLabelAlign.country"></el-input>
        </el-form-item>
        <el-form-item label="企业联系人">
          <el-input v-model="formLabelAlign.companyContacts"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话">
          <el-input v-model="formLabelAlign.companyContactsPhone"></el-input>
        </el-form-item>
        <el-form-item label="企业标签">
          <el-select
            v-model="value"
            filterable
            placeholder="请选择"
            @change="addtag(value)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.dictLabel"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="已选标签">
          <el-tag
            v-for="(item, index) in valuelist"
            :key="index"
            closable
            @close="tagdel(index)"
            style="margin-right: 10px"
          >
            {{ item }}
          </el-tag>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新建弹窗 -->
    <el-dialog title="新建企业" :visible.sync="dialogVisible2" width="50%">
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="企业名称">
          <el-input v-model="formLabelAlign.companyName"></el-input>
        </el-form-item>
        <el-form-item label="企业地址">
          <el-input v-model="formLabelAlign.companyCode"></el-input>
        </el-form-item>
        <el-form-item label="所属区域">
          <el-input v-model="formLabelAlign.country"></el-input>
        </el-form-item>
        <el-form-item label="企业联系人">
          <el-input v-model="formLabelAlign.companyContacts"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话">
          <el-input v-model="formLabelAlign.companyContactsPhone"></el-input>
        </el-form-item>
        <el-form-item label="企业标签">
          <el-select
            v-model="value"
            filterable
            placeholder="请选择"
            @change="addtag(value)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.dictLabel"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="已选标签">
          <el-tag
            v-for="(item, index) in valuelist"
            :key="index"
            closable
            @close="tagdel(index)"
            style="margin-right: 10px"
          >
            {{ item }}
          </el-tag>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="confirm2()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { read, utils } from "xlsx";
import JSZip from "jszip";
import FileSaver from "file-saver";
import {
  GetumsCompany,
  PostumsCompany,
  PutumsCompanyupdate,
  DeleteumsCompany,
  GetexportCompany,
  GetexportTemplate,
  PostdownloadQrCode,
  GetsysDictType,
  GetsysDictTypelistData,
} from "@/request/api";
Vue.use(ElementUI);

var token = localStorage.getItem("token");
export default {
  data() {
    return {
      id: "",
      fileList: [], //上传文件列表
      tableData: [], // 表数据
      tableDatas: [], // 表数据
      //----------------------------
      dialogVisible: false, //编辑弹出框判定
      dialogVisible2: false, //新建弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        companyName: "",
        companyCode: "",
        country: "",
        companyLabel: "",
        companyContacts: "",
        companyContactsPhone: "",
      },
      input: "",
      currentPage: 1, //分页
      pageNum: 1,
      pageSize: 10,
      total: 1,
      imgSrcList: [], // 图片合集
      multipleSelection: [], //复选框组
      fileList: [],
      headers: { Authorization: token },
      options: [], //类型列表
      value: "", //选中值
      valuelist: [], //已选标签
    };
  },
  methods: {
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    correct(file, fileList) {
      this.seelist();
      this.$message({
        message: "上传成功",
        type: "success",
      });
    },

    //分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.seelist();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.seelist();
    },
    //-------------------------------------------
    // 导出图片
    downloadImg(v) {
      // console.log(v);
      PostdownloadQrCode({ ids: [v.id] }).then((res) => {
        const url = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(url);
        a.download = "二维码.zip";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        // console.log(res);
      });
    },
    // 批量导出
    download() {
      var erlist = [];
      this.multipleSelection.map((item) => {
        // console.log(res);
        erlist.push(item.id);
      });
      PostdownloadQrCode({ ids: erlist }).then((res) => {
        const url = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(url);
        a.download = "二维码.zip";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        // console.log(res);
      });
    },
    // 导出表格
    downloads() {
      GetexportCompany().then((res) => {
        const url = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(url);
        a.download = "企业.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    // 导出模板
    muban() {
      GetexportTemplate().then((res) => {
        const url = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(url);
        a.download = "企业模板.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    // ---------------------------
    // unpack(arr, fileName) {
    //   const _this = this;
    //   const zip = new JSZip();
    //   const cache = {};
    //   const list = [];
    //   arr.forEach((item) => {
    //     const promise = _this.getImgArrayBuffer(item.fileUrl).then((data) => {
    //       // 下载文件, 并存成ArrayBuffer对象(blob)
    //       zip.file(item.renamefileName, data, { binary: true }); // 逐个添加文件
    //       cache[item.renamefileName] = data;
    //     });
    //     list.push(promise);
    //   });
    //   Promise.all(list).then(() => {
    //     zip
    //       .generateAsync({ type: "blob" })
    //       .then((content) => {
    //         // 生成二进制流
    //         FileSaver.saveAs(content, fileName); // 利用file-saver保存文件  自定义文件名
    //         _this.$message.success("压缩完成");
    //       })
    //       .catch((res) => {
    //         _this.$message.error("文件压缩失败");
    //       });
    //   });
    // },
    //  (url) {
    //   return new Promise((resolve, reject) => {
    //     const xmlhttp = new XMLHttpRequest();
    //     xmlhttp.open("GET", url, true);
    //     xmlhttp.responseType = "blob";
    //     xmlhttp.onload = function () {
    //       if (this.status === 200) {
    //         resolve(this.response);
    //       } else {
    //         reject(this.status);
    //       }
    //     };
    //     xmlhttp.send();
    //   });
    // },
    // 复选框组
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    //修改企业数据
    // 新增标签
    addtag(v) {
      // console.log(v);
      this.options.map((item, index) => {
        if (v == item.id) {
          // console.log(this.valuelist);
          this.valuelist.push(item.dictLabel);
          this.formLabelAlign.companyLabel = this.valuelist.join(",");
        }
      });
    },
    // 删除已选标签
    tagdel(v) {
      this.valuelist.splice(v, 1);
      this.formLabelAlign.companyLabel = this.valuelist.join(",");
    },
    handleUpdate(v) {
      // console.log(v);
      this.id = v.id;
      this.formLabelAlign.companyName = v.companyName;
      this.formLabelAlign.companyCode = v.companyCode;
      this.formLabelAlign.country = v.country;
      this.formLabelAlign.companyContacts = v.companyContacts;
      this.formLabelAlign.companyContactsPhone = v.companyContactsPhone;
      this.formLabelAlign.companyLabel = v.companyLabel;
      this.valuelist = this.formLabelAlign.companyLabel.split(",");
      // console.log(this.valuelist);
      this.dialogVisible = true;
    },
    // 修改企业数据确认
    confirm() {
      PutumsCompanyupdate({
        companyCode: this.formLabelAlign.companyCode,
        companyContacts: this.formLabelAlign.companyContacts,
        companyContactsPhone: this.formLabelAlign.companyContactsPhone,
        companyLabel: this.formLabelAlign.companyLabel,
        companyName: this.formLabelAlign.companyName,
        country: this.formLabelAlign.country,
        createTime: "",
        deleteFlag: "",
        id: this.id,
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.value = "";
          this.seelist();
          this.dialogVisible = false;
        }
      });
    },
    //添加企业数据
    clickadd() {
      this.formLabelAlign.companyName = "";
      this.formLabelAlign.companyCode = "";
      this.formLabelAlign.country = "";
      this.formLabelAlign.companyLabel = "";
      this.formLabelAlign.companyContacts = "";
      this.formLabelAlign.companyContactsPhone = "";
      this.value = "";
      this.valuelist = [];
      this.dialogVisible2 = true;
    },
    // 添加企业数据确认
    confirm2() {
      PostumsCompany({
        companyCode: this.formLabelAlign.companyCode,
        companyContacts: this.formLabelAlign.companyContacts,
        companyContactsPhone: this.formLabelAlign.companyContactsPhone,
        companyLabel: this.formLabelAlign.companyLabel,
        companyName: this.formLabelAlign.companyName,
        country: this.formLabelAlign.country,
        createTime: "",
        deleteFlag: "",
        id: "",
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.seelist();
          this.dialogVisible2 = false;
        }
      });
    },
    // 删除企业信息
    handleDelete(v) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeleteumsCompany({ id: v.id }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 搜索企业信息
    sousuo() {
      this.seelist();
    },
    // 标签类型列表
    seelist2() {
      GetsysDictTypelistData({
        dictSort: 0,
        dictType: "标签",
        pageNum: 1,
        pageSize: 100000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.options = res.data.list;
        }
      });
    },
    // 企业列表
    seelist() {
      GetumsCompany({
        companyName: this.input,
        country: "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableDatas = res.data.list;
          this.currentPage = res.totalPage;
          this.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.seelist(); //企业列表
    this.seelist2(); //标签列表
  },
};
</script>
<style lang="scss" scoped>
.upload-demo {
  width: 100%;
}
.flex-display {
  margin: 10px 8px 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.el-upload {
  margin-left: 40px;
  .el-btn {
    font-size: 16px;
  }
  .el-upload-tip {
    display: inline;
    font-size: 12px;
  }
}
.file-ipt {
  width: 100px;
  height: 40px;
  line-height: 36px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  button {
    background-color: #409eff;
  }
}
input #file-upload-button {
  background-color: #409eff;
}

.el-upload__tip {
  margin-top: 20px;
  margin-left: 10px;
}
.block {
  text-align: center;
  margin-top: 15px;
}
</style>